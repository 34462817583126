.ant-tabs-tab {
	.anticon {
		margin-right: 0 !important;
		font-size: 13px;
	}

	.anticon.pane {
		display: flex;
		align-items: center;
		margin-right: 0;

		svg {
			margin-right: 6px;
		}

		p {
			padding-top: 0;
			margin-left: 0;
		}
	}
}

.statusIcon + span {
	margin-left: 8px;
}
