.mediasPreviewList {
	display: flex;
	flex-wrap: wrap;

	.playerWrapper,
	.ant-image {
		margin: 0 10px 10px 0;
	}
}
.filesPreviewList {
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		margin: 0 0 1em;
		padding: 0;
	}

	a {
		font-size: 0.8rem;
		display: block;

		.anticon {
			font-size: 1.3em;
			display: inline-block;
			position: relative;
			top: 1px;
			margin-right: 0.4em;
		}
		span {
			//display: block;
			//border: 1px solid ;
		}
	}
}
