.control-points-registry {
	.rfid-cell:hover {
		.remove-rfid-btn {
			visibility: visible;
		}
	}
	.remove-rfid-btn {
		visibility: hidden;
	}
}
