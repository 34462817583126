
.detours-schedules-registry-modal {
  &-interval {
    width: 100%;
  }
  &-repeater-type {
    width: 150px;
    .ant-radio-wrapper {
      display: flex;
      align-items: center;
      height: 32px;
      margin-bottom: 8px;
    }
  }

  &-inputs {
    padding-top: 42px;
  }

  &-finalCount {
    width: 160px;
  }

  &-rowSettings {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &-colOneThreeSettings {
    text-align: right;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
  }

  &-colTwoSettings {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
  }

  &-colFourAfterEmptySettings {
    padding-top: 58px;
    padding-bottom: 8px;
    padding-left: 20px;
  }

  &-colFourSettings {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
  }

  &-rowAfterEmpty {
    padding-top: 63px;
    text-align: right;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    padding-left: 20px;
  }

  &-colOneThreeBlockOneSettings {
    text-align: right;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 45px;
  }

  &-colTwoThreeBlockOneSettings {
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 13px;
  }

  &-selectSettings {
    padding-left: 0px;
    padding-top: 11px;
    padding-bottom: 11px;
  }

  &-newSettingsFirstRow {
    display: flex;
    flex-direction: column;
    //margin-right: 10px;

    padding-top: 57px;
    padding-bottom: 15px;
    padding-left: 100px;
  }
}
