.PageForm {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.PageFormTitle {
		width: 100%;
		border-bottom: 3px solid #f0f2f5;
		padding: 5px 15px 4px 20px;
		font-size: 20px;
	}

	#PageFormData {
		flex: 1 1;
		width: 100%;
		display: flex;
		flex-direction: column;
		padding: 0 20px 20px 20px;

		.PageFormDataTitle {
			font-size: 14px;
			font-weight: 600;
			color: @input-color;
			margin: 20px 0 10px 0;
			//padding: 0 20px 10px 20px;
		}
		//.FieldsLine {
		//  padding: 10px 20px;
		//}

		.PageFormDataTable {
			flex: 1 1;
		}

		.PageFormFooter {
			text-align: right;
			margin-top: 20px;
			//padding: 15px 20px;
			.cancelButton {
				margin-right: 15px;
			}
			.ant-form-item {
				margin-bottom: 0;
			}
		}

		.NoRequiredField {
			label {
				margin-left: 8px;
			}
		}
		.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
			border-color: #1890ff;
		}
	}
}
