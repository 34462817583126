.col-route-map {
	display: flex;
}

.routeMaps {
	.routeMapsConfig,
	.routeMapsContainer {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;

		.routeMapContainer {
			position: relative;
			width: 100%;
			height: 100%;
			.react-transform-wrapper {
				position: absolute;
				width: 100%;
				height: 100%;
				//.react-transform-content{
				//  width: max-content;
				//}
				.routeMapImage {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}

		.ant-result .anticon.anticon-arrow-left {
			font-size: 40px;

			.route-map {
				flex: auto;
				background: #f9dcc4;
			}
		}
	}
	.buttonBlock {
		position: absolute;
		z-index: 1000;
	}

	.leaflet-div-icon {
		background-color: transparent;
		border: none;
	}

	.my-icon {
		color: red
	}
	.my-icon:after{
		content: ' ';
	}
}
