.dashboard {
    &-panel {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        min-height: 0;
        background-color: #fff;
        border: 1px solid #d9d9d9;
        &-header {
            border-bottom: 1px solid #d9d9d9;
            padding: 6px 12px;
            cursor: move;
            &:hover {
                background: #f7f7f7;
            }
        }
        &-content {
            //padding: 6px 12px;
            width: 100%;
            //height: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            min-height: 0;
        }
    }
}