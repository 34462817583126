#detourDataView {
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0;
  }
}

tr > th {
  background-color: rgba(211, 211, 211, 0.479);
}

.calendar-title {
  display: flex;
  justify-content: space-between;
}

.special-hidden {
  visibility: hidden;
}

.calendar-popover-hover {
  .ant-popover-title {
    background-color: #39839d;
    color: white;
  }

  .ant-popover-inner-content {
    background-color: white;
    color: grey;
  }
}

.calendar-detours {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  .detours-short {
    background-color: rgba(211, 211, 211, 0.479);
  }
}

