//@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd';
@import '~react-calendar-timeline/lib/Timeline.css';
@import '~mobile-inspections-base-ui/dist/index';
@import '~rt-design/lib/index';

@import '~react-grid-layout/css/styles.css';
@import '~react-resizable/css/styles.css';

@import './constants/variables';
@import './components/Base/PageForm';
@import './components/Catalog/Catalog.less';
@import './components/RouteMaps/RouteMap/RouteMaps.less';
@import './components/Detours/Detours.less';
@import './components/Schedules/Schedules.less';
@import './components/ControlPoints/ControlPoints';
@import "./components/Analytics/Analytics.less";
@import "./components/Management/Dashboard/Dashboard";

#root {
	height: 100vh;
}

.SplitPane {
	position: static !important;
	display: flex;
	width: 100%;
	.Pane {
		display: flex;
		overflow-x: hidden;
	}
	.Resizer.vertical {
		width: 4px;
		background: #f0f2f5;
		&:hover {
			cursor: col-resize;
			//background: red;
		}
	}
}

.m-0 {
	margin: 0;
}
.m-8 {
	margin: 8px;
}

/** Top Margins */
.mt-0 {
	margin-top: 0px;
}
.mt-8 {
	margin-top: 8px;
}
.mt-16 {
	margin-top: 16px;
}

/** Right Margins */
.mr-0 {
	margin-right: 0px;
}
.mr-8 {
	margin-right: 8px;
}
.mr-16 {
	margin-right: 16px;
}

/** Bottom Margins */
.mb-0 {
	margin-bottom: 0px;
}
.mb-8 {
	margin-bottom: 8px;
}
.mb-16 {
	margin-bottom: 16px;
}

/** Left Margins */
.ml-4 {
	margin-left: 4px;
}
.ml-8 {
	margin-left: 8px;
}
.ml-16 {
	margin-left: 16px;
}
/** X-axis*/
.mx-16 {
	margin-left: 16px;
	margin-right: 16px;
}

/** Y-axis*/
.my-0 {
	margin-top: 0px;
	margin-bottom: 0px;
}
.my-8 {
	margin-top: 8px;
	margin-bottom: 8px;
}
.my-16 {
	margin-top: 16px;
	margin-bottom: 16px;
}

/** Paddings */
.p-0 {
	padding: 0;
}
.p-8 {
	padding: 8px;
}

.px-8 {
	padding-left: 8px;
	padding-right: 8px;
}
.py-8 {
	padding-top: 8px;
	padding-bottom: 8px;
}
.py-16 {
	padding-top: 16px;
	padding-bottom: 16px;
}

.pb-8 {
	padding-bottom: 8px;
}
.pb-16 {
	padding-bottom: 16px;
}

.pt-0 {
	padding-top: 0;
}
.pt-8 {
	padding-top: 8px;
}
.pt-16 {
	padding-top: 16px;
}
/*Tabs header*/

.anticon.pane p {
	font-size: 13px;
	margin-left: 4px;
	padding-top: 5px;
	padding-bottom: 0px;
	margin-bottom: 0px;
}

.anticon.pane {
	display: flex;
	flex-direction: row;
}

/* react-calendar-timeline package style*/
.react-calendar-timeline {
	.rct-dateHeader {
		border-bottom: 0px;
		border-left: 0px;
	}
	.rct-dateHeader-primary {
		color: black;
	}
	.rct-header-root {
		background: #eff6f9;
		width: 100%;
		max-width: 1650px;
	}
	.rct-scroll {
		width: 100%;
		max-width: 1600px;
	}
}

.lastSelectModal .rt-form-body {
	overflow: visible;
}

.ant-menu-sub.ant-menu-inline {
	background-color: transparent;
}

.ant-table-cell:hover .BaseTable__column-resizer {
	visibility: visible;
	opacity: 0.5;
}

.ant-tooltip-content a {
	color: white;
}

.resultRouteForm {
	height: 40%;
}
