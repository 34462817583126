.mi-map-popover-hover {
	& .ant-popover-inner-content {
		background-color: #fff;
		color: #000;
	}
	& .ant-popover-arrow-content {
		background-color: #fff;
	}
	& .popContainer {
		width: 230px;
		height: 50px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		white-space: pre-line;
		& .popAddressLine {
			color: rgba(0, 0, 0, 0.4);
			word-break: break-all;
		}
	}
}
